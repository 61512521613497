import * as React from 'react';
import Layout from '../components/layout.js';

export default function IndexPage() {
  return (
    <Layout
      title="Revista colibrí"
      description="Actual numero de la Revista Colibrí"
    >
      <h1>Elije alguno de nuestros números para leer online</h1>
      <iframe
        src="https://issuu.com/home/published/colibri-6-a4"
        title="Revista Colibrí No. 6"
        width="600"
        height="400"
      ></iframe>
    </Layout>
  );
}
